<template>
  <Layout title="Onboarding" :footer="false" :main-class-map="'py-10 mx-4 lg:mx-0'" :quote-button-allowed="false">
    <template #body>
      <div class="buttons-wrapper">
        <div class="dark:text-white pb-4">
          <h2 class="text-black font-semibold text-center text-xl pb-4">Great work!</h2>
          <p>Now, test out your tool from your customer's POV! After that you can customize your services/prices to match your business!</p>
        </div>
        <div class="buttons-block">
          <MainButton
            title="Test from your Customer's POV!"
            class-map="mt-4 mb-6 inverted"
            @click="goToQuote"
          />

          <div class="title medium divider">
            <span>or</span>
            <div class="line"></div>
          </div>

          <MainButton
            title="Schedule a Call with US"
            class-map="mt-4 mb-2 grey"
            @click="goToSchedule"
          />
          <p class="notes"><span style="color: red;">*</span>Where we can set up the tool for you</p>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup>
import MainButton from '../../Components/Button/MainButton.vue'
import Layout from '../../Landing/Layouts/Layout.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const router = useRouter()
const store = useStore()
function goToQuote() {
  const website = store.getters['website/website']
  window.open('http://' + website.code + '.' + window.location.host + '/quote?preview=true', '_blank');
}

function goToSchedule() {
  router.push({ path: '/quide-callendly' })
}
</script>

<style scoped lang="scss">
.buttons-wrapper {
  display: flex;
  flex-direction: column;
  margin: 100px auto 0;
  max-width: 475px;
  width: 100%;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px rgba(64, 64, 64, 0.4);
  border-radius: 8px;
  
  @media screen and (max-width: 768px) {
    box-shadow: none;
    padding: 16px;
    margin: 0;
    justify-content: space-around;
    height: 100vh;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: rgba(38, 38, 38, 1);
  }
  
  p {
    text-align: center;
  }
  
  .divider {
    position: relative;
    height: 20px;
    width: 100%;
    
    span {
      text-transform: uppercase;
      position: absolute;
      left: calc(50% - 17px);
      top: 0;
      z-index: 3;
      padding: 0 8px;
      background-color: white;
      color: rgba(102, 102, 102, 1);
      font-size: 12px;
      font-weight: 600;
    }
    
    .line {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid rgba(211, 212, 213, 1);
    }
  }
  
  button {
    width: 100%;
    border-radius: 8px;
    
  }
  
  .notes {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
  }
}
</style>
